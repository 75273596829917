import React, { Component } from "react";
import { Box, Container, withWidth } from "@material-ui/core";
import moment from "moment-timezone";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
import styles from "./style.module.scss";
import NavBar from "../../components/Navbar/Navbar";
import { connect } from "react-redux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import { LoginSelectors } from "../../Redux/LoginRedux";
import { OrderSelectors } from "../../Redux/OrderRedux";
import Swal from "sweetalert2";
import { Grid, Divider, withStyles, withTheme, Modal } from "@material-ui/core";
import createUrlToScreen from "../../Services/Utils";
import Button from "@material-ui/core/Button";
import Api from "../../Services/Api";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import CreateAppointment from "../../components/CreateAppointment/CreateAppointment";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import Loading from "../../components/Loading/Loading";

import "./CustomCalender.css";

const classes = (theme) => {
  return {
    orderButton: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
};

class BookingAppointment extends Component {
  constructor(props) {
    console.log("BookingAppointment props ", props);
    super(props);
    let serviceList = this.selectedService();
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    this.state = {
      serviceList: serviceList,
      date: today,
      today: today,
      availableTimeSlots: [],
      availableTimeSlotsError: "",
      holidaysInfo: [],
      holidaysInfoError: "",
      selectedProductsInfo: [],
      selectedTime: null,
      showCustomerFormModal: false,
      showloginModal: false,
      loading: true,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    console.log("this.props.selectedProducts ", this.props.selectedProducts);
    const selectedProductsInfo = this.props.selectedProducts.map((product) => ({
      product_id: product.product_id,
      variation_id: product.variation_id,
      staff_id: product.category_id,
    }));

    this.setState({ selectedProductsInfo }, () => {
      this.getTimeSlotApi();
    });
    this.getHolidaysApi();
  }

  // handleDateChange = (newDate) => {
  //   this.setState({ date: newDate }, () => {
  //     this.getTimeSlotApi();
  //   });
  // };
  // handleDateChange = (newDate) => {
  //   // Check if the selected date is a holiday
  //   const isHoliday = this.state.holidaysInfo.some(
  //     (holiday) => holiday.date === newDate.toISOString().split("T")[0]
  //   );

  //   if (isHoliday) {
  //     toast.success("Today is holiday...");

  //     return; // Prevent further execution if a holiday is selected
  //   }

  //   // Proceed with setting the selected date and calling the API
  //   this.setState({ date: newDate }, () => {
  //     this.getTimeSlotApi();
  //   });
  // };
  handleDateChange = (newDate) => {
    const selectedDate = newDate.toISOString().split("T")[0]; // format selected date to YYYY-MM-DD
    const holiday = this.state.holidaysInfo.find(
      (holiday) =>
        selectedDate >= holiday.from_day && selectedDate <= holiday.to_day
    );

    this.setState({ date: newDate }, () => {
      this.getTimeSlotApi();
    });

    if (holiday) {
      toast.success(
        `Dear Valued Customer, please be aware that we will not be operating on ${selectedDate} in celebration of ${holiday.reason}.`
      );
      return; // Prevent further actions if it's a holiday
    }
  };

  // handleDateChange = (newDate) => {
  //   // Find the holiday that matches the selected date
  //   const holiday = this.state.holidaysInfo.find(
  //     (holiday) => holiday.date === newDate.toISOString().split("T")[0]
  //   );
  //   // Proceed with setting the selected date and calling the API if not a holiday
  //   this.setState({ date: newDate }, () => {
  //     this.getTimeSlotApi();
  //   });
  //   // If a holiday is found, show the reason in the toast message
  //   if (holiday) {
  //     toast.success(
  //       `Dear Valued Customer, please be aware that we will not be operating on ${holiday.date} in celebration of ${holiday.reason}`
  //     );

  //     return; // Prevent further execution if a holiday is selected
  //   }
  // };
  // Function to render a red dot for holiday dates
  tileContent = ({ date, view }) => {
    const selectedDate = date.toISOString().split("T")[0]; // format selected date to YYYY-MM-DD
    const holiday = this.state.holidaysInfo.find(
      (holiday) =>
        selectedDate >= holiday.from_day && selectedDate <= holiday.to_day
    );

    if (holiday) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "6px",
              height: "6px",
              backgroundColor: "red",
              borderRadius: "50%",
              marginTop: "5px", // Adjust as needed for proper alignment
              paddingLeft: "5px",
            }}
          />
        </div>
      );
    }

    return null; // No content for non-holiday dates
  };
  // Method to check if a date is a holiday
  // isHoliday = (date) => {
  //   return this.state.holidaysInfo.some(holiday =>
  //     new Date(holiday.date).toDateString() === date.toDateString()
  //   );
  // };
  selectedService = () => {
    let result = [];
    let selectedProduct = this.props.historyJsonItem
      ? this.props.historyJsonItem.selectedProducts
      : this.props.selectedProducts;

    let selectedMenuItem = this.props.historyJsonItem
      ? this.props.historyJsonItem.menuItems
      : this.props.menuItems;
    selectedProduct.forEach((selectedProduct) => {
      let foundItem = selectedMenuItem.find(
        (menuItem) => menuItem.item_id === selectedProduct.product_id
      );
      if (foundItem) {
        result.push(foundItem.item_name);
      }
    });
    return result;
  };

  openCustomerFormModal = () => {
    if (this.props.isUserLoggedIn) {
      this.setState({
        showCustomerFormModal: true,
      });
    } else {
      this.setState({
        showloginModal: true,
        showLoadingAnimation: false,
      });
    }
  };

  closeCustomerFormModal = () => {
    this.setState({
      showCustomerFormModal: false,
    });
  };
  handleModalClose = () => {
    this.setState({ showCustomerFormModal: false });
  };
  handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  // Function to disable holidays in the calendar
  // disableHolidayDates = ({ date }) => {
  //   // Check if the date is a holiday
  //   const isHoliday = this.state.holidaysInfo.some(
  //     (holiday) => holiday.date === date.toISOString().split("T")[0]
  //   );

  //   return isHoliday;
  // };
  // Custom tile content to show a dot for holidays
  //  tileContent = ({ date }) => {
  //   return this.isHoliday(date) ? (
  //     <div className="holiday-dot" />
  //   ) : null;
  // };
  getHolidaysApi = async () => {
    let apiService = Api.create();
    let requestObj = {
      business_id: this.props.restaurantInfo?.business_id,
    };
    console.log("Final requestObj 1:", requestObj);

    apiService.getHolidays(requestObj).then((response) => {
      console.log("Holidays Response ", response?.data?.data);
      if (response.status === 200) {
        this.setState({
          holidaysInfo: response?.data?.data || [],
          // loading: false,
        });
      } else {
        this.setState({
          holidaysInfoError: response?.data?.status,
          // loading: false,
        });
      }
    });
  };
  getTimeSlotApi = async () => {
    let apiService = Api.create();
    console.log(
      "this.state.selectedProductsInfo : ",
      this.state.selectedProductsInfo
    );

    let service_ids = this.state.selectedProductsInfo.map((product) => {
      return product.product_id;
    });
    console.log("Filtered service_ids:", service_ids);
    // let staff_ids = this.state.selectedProductsInfo.map((product) => {
    //   return product.staff_id;
    // });
    let staff_ids = this.state.selectedProductsInfo.map(
      (product) => product.staff_id
    );

    console.log("Filtered staff_ids:", staff_ids); // Check the contents of staff_ids

    const formattedDate = moment(this.state.date).format("YYYY-MM-DD");

    if (this.props.tabValue === 1) {
      let requestObj = {
        business_id: this.props.restaurantInfo?.business_id,
        service_id: service_ids,
        date: formattedDate,
        staff_id: staff_ids,
      };
      console.log("Final requestObj 1:", requestObj);

      apiService.getTimeSlots(requestObj).then((response) => {
        if (response.status === 200) {
          this.setState({
            availableTimeSlots:
              response?.data?.status_message?.appointments || [],
            loading: false,
          });
        } else {
          this.setState({
            availableTimeSlotsError: response?.data,
            loading: false,
          });
        }
      });
    } else {
      let requestObj = {
        business_id: this.props.restaurantInfo?.business_id,
        service_id: service_ids,
        date: formattedDate,
        staff_id: 0,
      };
      console.log("Final requestObj 0:", requestObj);
      apiService.getTimeSlots(requestObj).then((response) => {
        if (response.status === 200) {
          this.setState({
            availableTimeSlots:
              response?.data?.status_message?.appointments || [],
            loading: false,
          });
        } else {
          this.setState({
            availableTimeSlotsError: response?.data,
            loading: false,
          });
        }
      });

    }
  };

  handleTimeSlotSelection = (selectedTime) => {
    this.setState({ selectedTime });
  };

  groupTimeSlots = (timeSlots) => {
    const morning = [];
    const afternoon = [];
    const evening = [];

    timeSlots.forEach((timeSlot) => {
      const [time, period] = timeSlot.human_time.split(" ");
      const [hour, minute] = time.split(":").map(Number);

      if (period === "AM") {
        morning.push(timeSlot);
      } else if (period === "PM") {
        let hourInt = hour;
        if (hourInt < 12) {
          hourInt += 12;
        }

        const convertedTimeSlot = {
          ...timeSlot,
          hour_24: `${hourInt}:${minute < 10 ? "0" : ""}${minute}`,
        };

        if (hourInt < 17) {
          afternoon.push(convertedTimeSlot);
        } else {
          evening.push(convertedTimeSlot);
        }
      }
    });

    return { morning, afternoon, evening };
  };
  renderTimeSlotGroup = (group, title) => {
    console.log("this.state.holidaysInfo timeslots ", this.state.holidaysInfo);
    const selectedDateObj = new Date(this.state.date);
    const selectedDate = selectedDateObj.toISOString().split("T")[0];
    console.log("selectedDate ", selectedDate);

    // If not a holiday, proceed with rendering the time slots
    const currentDate = new Date().toDateString();
    const selectedDateString = selectedDateObj.toDateString();
    const isCurrentDate = currentDate === selectedDateString;

    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentSeconds = currentTime.getSeconds();

    return (
      <div>
        <h5>{title}</h5>
        <div className={styles.gridContainer}>
          {group.length > 0 ? (
            group.map((timeSlot) => {
              const [slotHours, slotMinutes, slotSeconds] = timeSlot.time
                .split(":")
                .map(Number);

              // Only apply the isPast logic if it's the current date
              const isPast =
                isCurrentDate &&
                (currentHours > slotHours ||
                  (currentHours === slotHours &&
                    currentMinutes > slotMinutes) ||
                  (currentHours === slotHours &&
                    currentMinutes === slotMinutes &&
                    currentSeconds > slotSeconds));

              return (
                <button
                  key={timeSlot.id}
                  className={`${styles.timeSlotButton} ${this.state.selectedTime &&
                    this.state.selectedTime.human_time === timeSlot.human_time
                    ? styles.selectedTimeSlot
                    : ""
                    }`}
                  style={{
                    padding: "10px",
                    margin: "5px",
                    fontSize: "15px",
                    cursor: isPast ? "not-allowed" : "pointer",
                    backgroundColor: isPast ? "#ccc" : "",
                    color: isPast ? "grey" : "",
                    border: isPast ? "none" : "1px solid #007bff",
                  }}
                  onClick={() =>
                    !isPast &&
                    this.handleTimeSlotSelection({
                      time: timeSlot.time,
                      human_time: timeSlot.human_time,
                    })
                  }
                  disabled={isPast}
                >
                  {timeSlot.human_time}
                </button>
              );
            })
          ) : (
            <p>No available time slots</p>
          )}
        </div>
      </div>
    );
  };

  // renderTimeSlotGroup = (group, title) => {
  //   const currentDate = new Date().toDateString(); // Get the current date as a string
  //   const selectedDateObj = new Date(this.state.date);
  //   const selectedDate = selectedDateObj.toDateString();

  //   const isCurrentDate = currentDate === selectedDate;

  //   const currentTime = new Date();

  //   // Extract current time components (hours, minutes, seconds)
  //   const currentHours = currentTime.getHours();
  //   const currentMinutes = currentTime.getMinutes();
  //   const currentSeconds = currentTime.getSeconds();

  //   return (
  //     <div>
  //       <h5>{title}</h5>
  //       <div className={styles.gridContainer}>
  //         {group.length > 0 ? (
  //           group.map((timeSlot) => {
  //             // Extract hours, minutes, and seconds from timeSlot.time
  //             const [slotHours, slotMinutes, slotSeconds] = timeSlot.time
  //               .split(":")
  //               .map(Number);

  //             // Only apply the isPast logic if it's the current date
  //             const isPast =
  //               isCurrentDate &&
  //               (currentHours > slotHours ||
  //                 (currentHours === slotHours &&
  //                   currentMinutes > slotMinutes) ||
  //                 (currentHours === slotHours &&
  //                   currentMinutes === slotMinutes &&
  //                   currentSeconds > slotSeconds));

  //             return (
  //               <button
  //                 key={timeSlot.id}
  //                 className={`${styles.timeSlotButton} ${
  //                   this.state.selectedTime &&
  //                   this.state.selectedTime.human_time === timeSlot.human_time
  //                     ? styles.selectedTimeSlot
  //                     : ""
  //                 }`}
  //                 style={{
  //                   padding: "10px",
  //                   margin: "5px",
  //                   fontSize: "15px",
  //                   cursor: isPast ? "not-allowed" : "pointer", // Visual cue for disabled state
  //                   backgroundColor: isPast ? "#ccc" : "", // Visual cue for disabled state
  //                   color: isPast ? "grey" : "",
  //                   border: isPast ? "none" : "1px solid #007bff",
  //                 }}
  //                 onClick={() =>
  //                   !isPast &&
  //                   this.handleTimeSlotSelection({
  //                     time: timeSlot.time,
  //                     human_time: timeSlot.human_time,
  //                   })
  //                 }
  //                 disabled={isPast} // Disable the button if the time slot is in the past
  //               >
  //                 {timeSlot.human_time}
  //               </button>
  //             );
  //           })
  //         ) : (
  //           <p>No available time slots</p>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };
  // renderTimeSlotGroup = (group, title) => {
  //   return (
  //     <div>
  //       <h5>{title}</h5>
  //       <div className={styles.gridContainer}>
  //         {group.length > 0 ? (
  //           group.map((timeSlot) => (
  //             <button
  //               key={timeSlot.id}
  //               className={`${styles.timeSlotButton} ${
  //                 this.state.selectedTime &&
  //                 this.state.selectedTime.human_time === timeSlot.human_time
  //                   ? styles.selectedTimeSlot
  //                   : ""
  //               }`}
  //               style={{
  //                 padding: "10px",
  //                 margin: "5px",
  //                 fontSize: "15px",
  //               }}
  //               onClick={() =>
  //                 this.handleTimeSlotSelection({
  //                   time: timeSlot.time,
  //                   human_time: timeSlot.human_time,
  //                 })
  //               }
  //             >
  //               {timeSlot.human_time}
  //             </button>
  //           ))
  //         ) : (
  //           <p>No available time slots</p>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };
  formatDate(dateString) {
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const dateParts = dateString.split(" ");
    const day = dateParts[2];
    const month = monthMap[dateParts[1]];
    const year = dateParts[3];

    return `${day}/${month}/${year}`;
  }

  // modifyAppointment = () => {
  //   this.props.history.push("./order");
  // };

  modifyAppointment = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your booking progress will be lost!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Start Over!",
    }).then((result) => {
      if (result.value) {
        this.props.history.push(
          "/web/" +
          this.props.match?.params?.restaurant +
          "/" +
          this.props.match?.params?.location
        );
      }
    });
  };
  addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    console.log("result", result);
    return result;
  };

  render() {
    console.log("this.props.tabValue ", this.props.tabValue);
    const { width } = this.props;
    const isMobile = /xs|sm/.test(width);
    console.log("with ", width);
    const { date, availableTimeSlots, selectedTime } = this.state;
    console.log("selectedTime booking ", selectedTime);
    const today = new Date();
    // const formattedDate = this.formatDate(date.toDateString());
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const showFormattedDate = moment(date).format("MMM D, YYYY");
    console.log("formattedDate ", formattedDate);
    const { morning, afternoon, evening } =
      this.groupTimeSlots(availableTimeSlots);
    console.log(
      "this.state.selectedProductsInfo",
      this.state.selectedProductsInfo
    );
    // console.log("this.props.selectedTabValue book ", this.props.selectedTabValue);
    // Assuming this.props.restaurantInfo.info.appointment_settings.days_before_cancellation is "15"
    const noOfDaysInCalander = parseInt(
      this.props?.restaurantInfo?.info?.appointment_settings
        ?.no_days_shown_calendar,
      10
    );
    console.log("noOfDaysInCalander ", noOfDaysInCalander);
    // Now daysBeforeCancellation will be an integer value 15
    console.log("this.state.holidaysInfo ", this.state.holidaysInfo);
    const selectedDateObj = new Date(this.state.date);
    const selectedDate = selectedDateObj.toISOString().split("T")[0];
    // Check if the selected date is a holiday
    const holiday = this.state.holidaysInfo.find(
      (holiday) =>
        selectedDate >= holiday.from_day && selectedDate <= holiday.to_day
    );
    console.log("holiday ", holiday);
    return (
      <>
        <Box style={{ minHeight: "94vh" }}>
          <NavBar />
          <Container style={{ paddingTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",
                      marginBottom: "12px",
                    }}
                  >
                    Appointment Summary
                  </div>
                  <Divider />
                  <div style={{ marginTop: "13px" }}>
                    {this.state.serviceList[0]}
                  </div>
                  <div style={{ marginTop: "13px" }}>
                    Date : {showFormattedDate}
                    {/* Date : {noOfDaysInCalander === 0 ? "-" : showFormattedDate} */}
                  </div>
                  <div style={{ marginTop: "13px" }}>
                    Time : {selectedTime ? selectedTime.human_time : ""}
                    {/* {noOfDaysInCalander === 0
                      ? "-"
                      : selectedTime
                      ? selectedTime.human_time
                      : ""} */}
                  </div>
                </div>
              </Grid>

              <Divider orientation="vertical" flexItem />

              <Grid item xs={12} sm={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div>
                      <Calendar
                        onChange={this.handleDateChange}
                        value={this.state.date}
                        minDate={this.state.today}
                        maxDate={
                          noOfDaysInCalander === 0
                            ? this.state.today
                            : this.addDays(
                              this.state.today,
                              noOfDaysInCalander - 1
                            )
                        }
                        tileContent={this.tileContent} // Add tileContent prop here
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      {this.state.loading && (
                        <Box>
                          <Grid
                            container
                            justify="space-around"
                            // style={{ height: "calc(100vh - 40px)" }}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              style={{ maxHeight: "20vh", maxWidth: "20vh" }}
                            >
                              <Loading title="Loading Timeslots Please Wait!!!" />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {!this.state.loading && holiday ? (
                        <div>
                          <h3>Holiday Notice</h3>
                          <hr></hr>
                          <p style={{ letterSpacing: "1px", padding: "10px" }}>
                            {" "}
                            "Hello! We wanted to inform you that our services
                            will be unavailable on{" "}
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {holiday.date}
                            </span>{" "}
                            due to{" "}
                            <span style={{ fontWeight: "bold", color: "red" }}>
                              {holiday.reason}
                            </span>
                            ."
                          </p>
                        </div>
                      ) : (
                        <>
                          {this.renderTimeSlotGroup(morning, "MORNING")}
                          {this.renderTimeSlotGroup(afternoon, "AFTERNOON")}
                          {this.renderTimeSlotGroup(evening, "EVENING")}
                        </>
                      )}
                      {/* {!this.state.loading && (
                        <>
                          {this.renderTimeSlotGroup(morning, "MORNING")}
                          {this.renderTimeSlotGroup(afternoon, "AFTERNOON")}
                          {this.renderTimeSlotGroup(evening, "EVENING")}
                        </>
                      )} */}
                      {/* {!this.state.loading &&
                        (noOfDaysInCalander === 0 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "orangered",
                            }}
                          >
                            <h3>
                              No Available Slots for Booking Appointment, Please
                              Check the Appointment Settings !!!
                            </h3>
                          </div>
                        ) : (
                          <>
                            {this.renderTimeSlotGroup(morning, "MORNING")}
                            {this.renderTimeSlotGroup(afternoon, "AFTERNOON")}
                            {this.renderTimeSlotGroup(evening, "EVENING")}
                          </>
                        ))} */}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.modifyAppointment}
                          style={{
                            width: "150px",
                            height: "40px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          Go Back
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.openCustomerFormModal}
                          disabled={!selectedTime}
                          // disabled={!selectedTime || noOfDaysInCalander === 0}
                          style={{
                            width: "150px",
                            height: "40px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          Next
                        </Button>
                      </div>
                      {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.openCustomerFormModal}
                        disabled={!selectedTime}
                        style={{
                          width: "150px",
                          height: "40px",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        Next
                      </Button> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Modal
          open={this.state.showCustomerFormModal}
          onClose={this.closeCustomerFormModal}
          BackdropProps={{
            onClick: this.handleBackdropClick,
          }}
        >
          <div className={styles.customerModalStyle} id="modifiers">
            <CreateAppointment
              isUserLoggedIn={this.props.isUserLoggedIn}
              business_id={this.props.businessId}
              location_id={this.props.locationId}
              selectedProductsInfo={this.state.selectedProductsInfo}
              selected_date={formattedDate}
              selectedTime={selectedTime ? selectedTime.time : ""}
              handleCloseModal={this.handleModalClose}
            />
          </div>
        </Modal>

        <Modal open={this.state.showloginModal} disableBackdropClick>
          <div className={styles.loginModalStyle}>
            <LoginSignup
              nextScreen="null"
              onClose={() => this.setState({ showloginModal: false })}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedProducts: OrderSelectors.getProducts(state),
    menuItems: MenuSelectors.getMenuItems(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
    businessId: state.menu.business_id,
    locationId: state.menu.location_id,
    orderType: LoginSelectors.getOrderTypeId(state),
    userInfo: LoginSelectors.getUserInfo(state),
    variationList: MenuSelectors.getVariationList(state),
    token: LoginSelectors.getUserToken(state),
    restaurantTimeZone: MenuSelectors.getTimezone(state),
    restaurantName: MenuSelectors.getRestaurantName(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    orderStatus: state.order.orderStatus,
    selectedOrder: state.order.selectedOrder,
    tabValue: state.menu.tabValue,
    // selectedTabValue: state.order.selectedTabValue,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    withTheme(withTheme(withStyles(classes)(withWidth()(BookingAppointment))))
  )
);
