import React, { Component } from "react";
import MenuActions, { MenuSelectors } from "../../Redux/MenuRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Api from "../../Services/Api";
import MenuApi from "../../Services/MenuApi";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import { Grid, Box, Button, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./style.module.scss";
import background_1 from "../../assets/images/backimage_1.jpeg";
import moment from "moment";
import { Helmet } from "react-helmet";
import { withTheme } from "@material-ui/core/styles";
const google = window.google;

class DineInHome extends Component {
  constructor(props) {
    console.log("DineInHome ", props)
    super(props);
    this.state = {
      restaurantClosed: false,
      restaurantErrorMessage: "",
      ageVerification: false,
      noOfGuest: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getRestaurantInfo();
  }

  getRestaurantInfo = () => {
    const apiService = Api.create();
    apiService
      .getRestaurantInfoDineIn(
        this.props.match?.params?.restaurant,
        this.props.match?.params?.roomName,
        this.props.match?.params?.tableNumber,
        this.props.match?.params?.location == "main"
          ? null
          : this.props.match?.params?.location
      )
      .then((response) => {
        if (response.status == 200 && response.data?.business_id) {
          const responseInfo = response.data;
          const request = {
            business_id: responseInfo.business_id,
            location_id: responseInfo.location_id,
            room_name: this.props.match?.params?.roomName,
            table_number: this.props.match?.params?.tableNumber,
          };

          const apiServiceNew = MenuApi.create();

          apiServiceNew.getRestaurantDetailsDineIn(request).then((response) => {
            if (response.status == 200 && response.data?.available) {
              let info = response.data;
              info.room_name = this.props.match?.params?.roomName;
              info.table_number = this.props.match?.params?.tableNumber;

              var tipOptionText = ["No Tip"];
              var tipOption = [0];
              var newServerTipText = info.tip_options.map((tip_option) => {
                return tip_option + "%";
              });
              var newServerTip = info.tip_options.map((tip_option) => {
                return +tip_option;
              });

              info.businessDomain = this.props.match?.params?.restaurant;
              info.locationDomain = this.props.match?.params?.location;

              info.tip_options = tipOption.concat(newServerTip);
              info.tip_options_text = tipOptionText.concat(newServerTipText);
              const finalObj = { ...responseInfo, ...info };
              this.props.dispatch(
                OrderActions.setNewRestaurant(
                  responseInfo.business_id,
                  responseInfo.location_id,
                  responseInfo.intersection_plaza,
                  false,
                  finalObj
                )
              );

              this.props.dispatch(LoginActions.setOrderType(3));
            }
          });
        } else {
          this.setState({
            restaurantClosed: true,
            restaurantErrorMessage: response?.data?.status_message,
          });
        }
      });
  };

  isContinueDisabled = () => {
    if (
      this.state.isScheduled &&
      (this.state.datePicked == "" || this.state.timePicked == "")
    ) {
      return true;
    }
    if (this.props.orderType == 1 && this.state.latitude == "") {
      return true;
    }

    if (
      this.props.restaurantInfo?.info?.is_age_verificaton &&
      !this.state.ageVerification
    ) {
      return true;
    }
  };
  goToMenuScreen = () => {
    this.props.dispatch(OrderActions.setNoOfGuests(this.state.noOfGuest));
    this.props.dispatch(
      MenuActions.menuRequestDineIn(
        this.props.restaurantInfo?.info?.business_id,
        this.props.restaurantInfo?.info?.location_id,
        this.props.match?.params?.roomName,
        this.props.match?.params?.tableNumber
      )
    );
    this.props.history.push(`${this.props.match.url}/order`);
  };

  setOrderType = (orderType) => {
    this.props.dispatch(LoginActions.setOrderType(orderType));
    this.setState({ datePicked: "", timePicked: "" });
  };

  isAsapAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_asap_delivery ? true : false;
    else return this.props.restaurantInfo?.info?.is_asap_pickup ? true : false;
  };

  isScheduleAvailable = () => {
    if (this.props.orderType == 1)
      return this.props.restaurantInfo?.info?.is_scheduled_delivery
        ? true
        : false;
    else
      return this.props.restaurantInfo?.info?.is_scheduled_pickup
        ? true
        : false;
  };

  getAvailableDate = () => {
    const slots =
      this.props.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableDates = Object.keys(slots);
    return availableDates.map((date) => (
      <MenuItem value={date} key={date}>
        {moment(date).format("Do MMM, dddd")}
      </MenuItem>
    ));
  };

  getAvailableTime = () => {
    const slots =
      this.props.orderType == 1
        ? this.props.restaurantInfo?.info?.scheduled_delivery_slots
        : this.props.restaurantInfo?.info?.scheduled_pickup_slots;
    const availableSlots = slots[this.state.datePicked] ?? [];
    return availableSlots.map((time) => (
      <MenuItem value={time}>{time}</MenuItem>
    ));
  };

  getResults = (query) => {
    if (query.length > 3) {
      this.setState({ inputAddress: query });
      this.searchPlaces(query).then((response) => {
      });
    }
  };

  searchPlaces = async (query) => {
    var options = {
      types: ["address"],
      componentRestrictions: { country: "ca" },
    };
    var response = new google.maps.places.AutocompleteService();
    var self = this;
    var result = response.getPlacePredictions(
      {
        input: query,
        types: ["address"],
        componentRestrictions: { country: "ca" },
      },
      function (predictions, status) {
        if (status == "OK") {
          self.setState((state) => {
            return {
              searchResults: predictions,
              selectedAddress: state.selectedAddress,
              latitude: state.latitude,
              longitude: state.longitude,
            };
          });
          return predictions;
        }
      }
    );
    return await result;
  };

  removeCountryText = (description) => {
    return description.replace(", Canada", "");
  };

  getLocationInfo = async (place) => {
    var response = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    var self = this;
    response.getDetails(
      { placeId: place.place_id },
      function (exactLocation, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          self.setState((state) => {
            return {
              searchResults: [],
              selectedAddress:
                self.removeCountryText(place.description) +
                self.findPostalCode(exactLocation),
              latitude: exactLocation.geometry.location.lat(),
              longitude: exactLocation.geometry.location.lng(),
              inputAddress:
                place.description + self.findPostalCode(exactLocation),
            };
          });
        }
      }
    );
  };

  findPostalCode = (address) => {
    var postalCode = "";
    address.address_components.forEach((component) => {
      component.types.forEach((type) => {
        if (type == "postal_code") {
          postalCode = " , " + component.long_name;
        }
      });
    });
    return postalCode;
  };

  getSelectedAddressInfo = (place) => {
    if (place) {
      this.getLocationInfo(place);
    } else {
      this.setState({
        latitude: "",
        longitude: "",
        inputAddress: "",
      });
    }
  };

  setThemeColor = () => {
    if (
      this.props.restaurantInfo?.info?.theme_color &&
      this.props.restaurantInfo?.info?.theme_color !==
      this.props.theme.palette.secondary.main
    ) {
      this.props.onThemeColorChange(
        this.props.restaurantInfo?.info?.theme_color
      );
    }
  };

  handleGuestChange = (event) => {
    this.setState({
      noOfGuest: event.target.value,
    });
  };

  render() {
    this.setThemeColor();
    return (
      <>
        {this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              {this.props.restaurantInfo?.info?.business_name} | Order On The Go
            </title>
          </Helmet>
        )}
        {!this.props.restaurantInfo?.info?.business_name && (
          <Helmet>
            <title>
              Order On The Go | Takeout and Delivery from your favorite
              restaurants
            </title>
          </Helmet>
        )}
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={styles.backgroudImage}
            style={{
              minHeight: "calc(100vh - 40px)",
              backgroundImage:
                "url(" +
                this.props.restaurantInfo?.info?.background_image +
                ")",
            }}
          >
            {this.state.restaurantClosed && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid
                  container
                  style={{ minHeight: "30vh" }}
                  justify="center"
                  alignContent="center"
                >
                  <span style={{ fontStyle: "italic" }}>
                    {this.state.restaurantErrorMessage}
                  </span>
                  {/* <div className={styles.restaurantHours}>
                    <div className="font20Bold">Restaurant Hours</div>
                    <div className={styles.restaurantHoursSubHeading}>
                      <div className="font16Bold">Delivery Hours</div>
                      <div>8:00am - 9:00pm</div>
                    </div>
                    <div className={styles.restaurantHoursSubHeading}>
                      <div className="font16Bold">Pickup Hours</div>
                      <div>8:00am - 9:00pm</div>
                    </div>
                  </div> */}
                </Grid>
              </Grid>
            )}
            {!this.state.restaurantClosed && (
              <Grid container justify="center" className={styles.mainSection}>
                <Grid item xs={12} className={styles.topSection}>
                  <Grid container justify="center" alignItems="center">
                    {this.props.restaurantInfo?.info?.logo && (
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center" }}>
                          <img
                            className={styles.logo}
                            src={this.props.restaurantInfo?.info?.logo}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {this.props.restaurantInfo?.info
                        ?.logo_has_business_name !== "1" && (
                          <h1 style={{ textAlign: "center" }}>
                            {this.props.restaurantInfo?.info?.business_name}
                          </h1>
                        )}
                      <span style={{ textAlign: "center" }}>
                        {this.props.restaurantInfo?.info?.business_location}
                      </span>
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          paddingTop: 10,
                        }}
                      >
                        Contactless Dine In Experience
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {this.props.restaurantInfo?.info?.is_age_verificaton === 1 && (
                  <Grid
                    item
                    xs={12}
                    className={styles.padding30}
                    style={{ flexDirection: "row", marginTop: 10 }}
                  >
                    <Grid container>
                      <Grid xs={12}>
                        <div className="font16" style={{ marginBottom: 10 }}>
                          Are you 19 years old?
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <Button
                          size="large"
                          variant="contained"
                          style={{ marginRight: 5 }}
                          onClick={() =>
                            this.setState({ ageVerification: true })
                          }
                          color={this.state.ageVerification ? "secondary" : ""}
                        >
                          Yes
                        </Button>
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() =>
                            this.setState({ ageVerification: false })
                          }
                          color={this.state.ageVerification ? "" : "secondary"}
                        >
                          No
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    flexDirection: "row",
                    marginTop: 15,
                    textAlign: "center",
                  }}
                  className={styles.padding30}
                >
                  {this.props.restaurantInfo?.info?.waiter_name && (
                    <>
                      <div className="font16" style={{ paddingBottom: 10 }}>
                        You will be served by
                      </div>
                      <div className={styles.waiterNameStyle}>
                        {this.props.restaurantInfo?.info?.waiter_name}
                      </div>
                    </>
                  )}
                  <div
                    className="font16"
                    style={{ paddingBottom: 10, paddingTop: 10 }}
                  >
                    Table
                  </div>
                  <div className={styles.waiterNameStyle}>
                    {this.props.restaurantInfo?.info?.table_number}
                  </div>
                  <div className={styles.roomNameStyle}>
                    {this.props.restaurantInfo?.info?.room_name}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={3}
                  style={{
                    flexDirection: "row",
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <FormControl className={styles.formControl} fullWidth={true}>
                    <div className="font16" style={{ paddingBottom: 10 }}>
                      No of Guests
                    </div>
                    <Select
                      value={this.state.noOfGuest}
                      onChange={(event) => this.handleGuestChange(event)}
                    >
                      <MenuItem value={1}>1 person</MenuItem>
                      <MenuItem value={2}>2 people</MenuItem>
                      <MenuItem value={3}>3 people</MenuItem>
                      <MenuItem value={4}>4 people</MenuItem>
                      <MenuItem value={5}>5 people</MenuItem>
                      <MenuItem value={6}>6 people</MenuItem>
                      <MenuItem value={7}>7 people</MenuItem>
                      <MenuItem value={8}>8 people</MenuItem>
                      <MenuItem value={9}>9 people</MenuItem>
                      <MenuItem value={10}>10 people</MenuItem>
                      <MenuItem value={11}>11 people</MenuItem>
                      <MenuItem value={12}>12 people</MenuItem>
                      <MenuItem value={13}>13 people</MenuItem>
                      <MenuItem value={14}>14 people</MenuItem>
                      <MenuItem value={15}>15 people</MenuItem>
                      <MenuItem value={16}>16 people</MenuItem>
                      <MenuItem value={17}>17 people</MenuItem>
                      <MenuItem value={18}>18 people</MenuItem>
                      <MenuItem value={19}>19 people</MenuItem>
                      <MenuItem value={20}>20 people</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                  <Button
                    style={{ width: "100%" }}
                    color="secondary"
                    variant="contained"
                    className={styles.continueButton}
                    onClick={() => this.goToMenuScreen()}
                    disabled={this.isContinueDisabled()}
                  >
                    View Menu
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* <Switch>
          <Route exact path={`${this.props.match.path}/order`} component={OrderScreen} />
          <Route exact path={`${this.props.match.path}/checkout`} component={Checkout} />
          <Route exact path={`${this.props.match.path}/orderHistory`} component={OrderHistory} />
        </Switch> */}
      </>

      // <Button variant="contained" onClick={() => this.goToMenuScreen()}>Get Menu</Button>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    orderType: LoginSelectors.getOrderTypeId(state),
    isScheduled: LoginSelectors.getIsScheduled(state),
    selectedAddress: LoginSelectors.getSelectedAddress(state),
    businessInfo: MenuSelectors.getBusinessInfo(state),
  };
};

export default withRouter(connect(mapStateToProps)(withTheme(DineInHome)));
