import Immutable from "seamless-immutable";
import { createActions, createReducer } from "reduxsauce";
import calculateTip from "../Services/Calculations";
import { discountTypeLookup } from "../Lookups/discountTypeLookup";
import { encrypt } from "../Services/crypt.service";

const { Types, Creators } = createActions({
  addProduct: [
    "product_info",
    "selected_variation",
    "selected_modifiers",
    "tax_list",
    "quantity",
    "special_instructions",
  ],
  removeProduct: ["product_index"],
  clearOrder: {},
  placeOrder: [
    "payment_type",
    "business_id",
    "location_id",
    "order_type",
    "user_info",
    "selected_address",
    "payment_info",
    "is_schedule_order",
    "order_time",
    "bill_total",
    "delivery_fee",
    "instructions",
    "coupon_amount",
    "currentUrl",
  ],
  placeBookingOrder: [
    "payment_type",
    "business_id",
    "location_id",
    "user_info",
    "payment_info",
    "order_time",
    "bill_total",
    "order_type",
    "selected_address",
    "is_schedule_order",
    "delivery_fee",
    "instructions",
    "coupon_amount",
    "currentUrl",
  ],
  setPhoneDeliveryFee: ["business_id", "location_id", "latitude", "longitude"],
  setNewRestaurant: [
    "location_id",
    "business_id",
    "intersection_plaza",
    "favourite",
    "infoObj",
    "resetProduct",
  ],
  setPlayerId: ["playerId"],
  sendOrder: {},
  sendAppointmentOrder: {},
  orderSuccessful: {},
  phoneOrderSuccessful: {},
  successPhoneDeliveryFee: ["delivery_fee"],
  errorPhoneDeliveryFee: {},
  orderFail: {},
  setTipIndex: ["tipIndexValue"],
  setTipInputValue: ["tipValue"],
  applyCoupon: ["discountType", "value", "tax_list", "coupon_name"],
  removeCoupon: ["tax_list"],
  setNoOfGuests: ["noOfGuests"],
  updateSelectedOrder: ["selectedOrder"],
});

export const INITIAL_ORDER_STATE = Immutable({
  selected_products: [],
  restuarantInfo: {
    business_id: "",
    location_id: "",
    intersection_plaza: "",
    favourite: "",
    info: {},
  },
  playerId: "",
  door_dash_delivery_id: null,
  finalOrderJson: {},
  orderStatus: "",
  tipIndex: 1,
  tipInputValue: 0,
  couponInfo: {
    applied: false,
    discount_product_values: [],
    type: "",
    name: "",
    value: "",
    error: false,
  },
  phoneDeliveryFee: null,
  selectedOrder: {},
});

export const OrderTypes = Types;
export default Creators;

export const OrderSelectors = {
  getProducts: (state) => {
    return state.order.selected_products;
  },
  getTotalCost: (state) => {
    let totalSubCost = 0;
    let totalCost = 0;

    state.order.selected_products.forEach(
      (product) => (totalCost += +product.product_total + +product.product_tax)
    );

    state.order.selected_products.forEach(
      (product) => (totalSubCost += +product.product_total)
    );

    if (state.order.restuarantInfo.business_id !== "") {
      if (state.order.phoneDeliveryFee) {
        if (
          state.login.orderType == 1 &&
          (+state.order.phoneDeliveryFee.delivery_free_for_orders_above == 0 ||
            +totalSubCost <
            state.order.phoneDeliveryFee.delivery_free_for_orders_above)
        ) {
          totalCost += +state.order.phoneDeliveryFee.delivery_fee;
        }
      } else {
        if (
          state.login.orderType == 1 &&
          (+state.order.restuarantInfo.info.delivery_free_for_orders_above ==
            0 ||
            +totalSubCost <
            state.order.restuarantInfo.info.delivery_free_for_orders_above)
        ) {
          totalCost += +state.order.restuarantInfo.info.delivery_fee;
        }
      }

      if (+state.order.restuarantInfo.info.is_tip_available == 1) {
        if (state.order.tipIndex == 3 || state.order.tipIndex == 4) {
          if (state.order.tipInputValue) {
            totalCost += +state.order.tipInputValue;
          }
        } else {
          let subTotalCost = 0;
          state.order.selected_products.forEach(
            (product) => (subTotalCost += +product.product_total)
          );
          totalCost += +calculateTip(
            state.order.tipIndex,
            (+subTotalCost).toFixed(2)
          );
        }
      }
    }

    return (+totalCost).toFixed(2);
  },

  getTotalPhoneCostWithOrdeType: (state, orderType) => {
    let totalCost = 0;
    let totalSubCost = 0;
    state.order.selected_products.forEach(
      (product) => (totalCost += +product.product_total + +product.product_tax)
    );

    state.order.selected_products.forEach(
      (product) => (totalSubCost += +product.product_total)
    );

    if (state.order.restuarantInfo.business_id !== "") {
      if (state.order.phoneDeliveryFee) {
        if (
          orderType == 1 &&
          (+state.order.phoneDeliveryFee.delivery_free_for_orders_above == 0 ||
            +totalSubCost <
            state.order.phoneDeliveryFee.delivery_free_for_orders_above)
        ) {
          totalCost += +state.order.phoneDeliveryFee.delivery_fee;
        } else {
          if (
            orderType == 1 &&
            (+state.order.restuarantInfo.info.delivery_free_for_orders_above ==
              0 ||
              +totalSubCost <
              state.order.restuarantInfo.info.delivery_free_for_orders_above)
          ) {
            totalCost += +state.order.restuarantInfo.info.delivery_fee;
          }
        }

        if (state.order.restuarantInfo.info.is_tip_available == 1) {
          if (state.order.tipIndex == 3 || state.order.tipIndex == 4) {
            if (state.order.tipInputValue) {
              totalCost += +state.order.tipInputValue;
            }
          } else {
            let subTotalCost = 0;
            state.order.selected_products.forEach(
              (product) => (subTotalCost += +product.product_total)
            );
            totalCost += +calculateTip(
              state.order.tipIndex,
              (+subTotalCost).toFixed(2)
            );
          }
        }
      }
    }
    return (+totalCost).toFixed(2);
  },
  getTotalCostWithOrdeType: (state, orderType) => {
    let totalCost = 0;
    let totalSubCost = 0;
    state.order.selected_products.forEach(
      (product) => (totalCost += +product.product_total + +product.product_tax)
    );

    state.order.selected_products.forEach(
      (product) => (totalSubCost += +product.product_total)
    );

    if (state.order.restuarantInfo.business_id !== "") {
      if (
        orderType !== 2 &&
        (+state.order.restuarantInfo.info.delivery_free_for_orders_above == 0 ||
          +totalSubCost <
          state.order.restuarantInfo.info.delivery_free_for_orders_above)
      ) {
        totalCost += +state.order.restuarantInfo.info.delivery_fee;
      }
      if (state.order.restuarantInfo.info.is_tip_available == 1) {
        if (state.order.tipIndex == 3 || state.order.tipIndex == 4) {
          if (state.order.tipInputValue) {
            totalCost += +state.order.tipInputValue;
          }
        } else {
          let subTotalCost = 0;
          state.order.selected_products.forEach(
            (product) => (subTotalCost += +product.product_total)
          );
          totalCost += +calculateTip(
            state.order.tipIndex,
            (+subTotalCost).toFixed(2)
          );
        }
      }
    }
    return (+totalCost).toFixed(2);
  },
  getTotalCostofItems: (state) => {
    let totalCost = 0;
    state.order.selected_products.forEach(
      (product) => (totalCost += +product.product_total + +product.product_tax)
    );
    return (+totalCost).toFixed(2);
  },
  getTotalTaxes: (state) => {
    let totalTaxes = 0;
    state.order.selected_products.forEach(

      (product) => {
        console.log('+product.product_tax ', +product.product_tax)
        return (totalTaxes += +product.product_tax)
      }
    );
    console.log("(+totalTaxes).toFixed(2) ", (+totalTaxes).toFixed(2))
    return (+totalTaxes).toFixed(2);
  },
  getCountOfProducts: (state) => state.order.selected_products.length,
  getSubTotal: (state) => {
    let subTotalCost = 0;
    state.order.selected_products.forEach(
      (product) => (subTotalCost += +product.product_total)
    );
    return (+subTotalCost).toFixed(2);
  },
  getTotalAvailableQty: (state) => {
    const variationIdQuantities = state.order.selected_products.reduce(
      (result, selectedProduct) => {
        const { product_id, variation_id, quantity } = selectedProduct;
        const key = variation_id || product_id;

        if (result[key]) {
          result[key].quantity += quantity;
        } else {
          result[key] = { quantity, product_id, variation_id };
        }

        return result;
      },
      {}
    );

    const resultArray = Object.keys(variationIdQuantities).map((key) => ({
      key,
      product_id: variationIdQuantities[key].product_id,
      variation_id: variationIdQuantities[key].variation_id,
      total_quantity: variationIdQuantities[key].quantity,
    }));

    return resultArray;
  },

  getOldSubTotal: (state) => {
    let subTotalCost = 0;
    state.order.selected_products.forEach(
      (product) => (subTotalCost += +product.original_total)
    );
    return (+subTotalCost).toFixed(2);
  },

  getTotalTaxList: (state) => {
    let tax_list = [];
    console.log('state.order.selected_products', state.order.selected_products);

    state.order.selected_products.forEach((product) => {
      console.log('product:', product);

      product.taxes.forEach((productTaxObj) => {
        let existingTax = tax_list.find(tax => tax.tax_id === productTaxObj.tax_id);

        if (existingTax) {
          existingTax.tax_amount = (+existingTax.tax_amount + +productTaxObj.tax_amount).toFixed(2);
        } else {
          tax_list.push({ ...productTaxObj });
        }
      });
    });
    console.log('tax_list final ', tax_list)
    return tax_list;
  },

  // getTotalTaxList: (state) => {
  //   let tax_list = [];
  //   console.log('state.order.selected_products ', state.order.selected_products)
  //   state.order.selected_products.forEach((product) => {
  //     console.log('product 1', product)
  //     if (tax_list.length == 0) {
  //       tax_list = JSON.parse(JSON.stringify(product.taxes));
  //     } else {
  //       tax_list.forEach((taxObject) => {
  //         product.taxes.forEach((productTaxObj) => {
  //           if (productTaxObj.tax_id == taxObject.tax_id) {
  //             taxObject.tax_amount =
  //               +taxObject.tax_amount + +productTaxObj.tax_amount;
  //           }
  //         });
  //       });
  //     }
  //   });
  //   console.log('tax_list final ', tax_list)
  //   return tax_list;
  // },
  getFinalOrderJson: (state) => state.order.finalOrderJson,
  getIntersectionPlaza: (state) =>
    state.order.restuarantInfo.intersection_plaza,
  getFavourite: (state) => state.order.restuarantInfo.favourite,
  getRestaurantInfo: (state) => state.order.restuarantInfo,
  getTipIndex: (state) => state.order.tipIndex,
  getTipValue: (state) => {
    let tip = 0;
    if (state.order.restuarantInfo.business_id !== "") {
      if (+state.order.restuarantInfo.info.is_tip_available == 1) {
        if (state.order.tipIndex == 3 || state.order.tipIndex == 4) {
          tip = +state.order.tipInputValue;
        } else {
          let subTotalCost = 0;
          state.order.selected_products.forEach(
            (product) => (subTotalCost += +product.product_total)
          );
          tip = +calculateTip(
            state.order.tipIndex,
            (+subTotalCost).toFixed(2),
            state.order.orderType == 3
          );
        }
      }
    }
    return (+tip).toFixed(2);
  },
  getCouponInfo: (state) => state.order.couponInfo,
  getPhoneDeliveryInfo: (state) => state.order.phoneDeliveryFee,
};

export const addProduct = (
  state,
  {
    product_info,
    selected_variation,
    selected_modifiers,
    tax_list,
    quantity,
    special_instructions,
  }
) => {
  let couponChange = false;
  let newCouponInfo = {};
  let productJson = {};
  console.log("product_info ", product_info);
  productJson.product_id = product_info.item_id;
  productJson.quantity = quantity;
  productJson.special_instructions = special_instructions;
  productJson.category_id = product_info.category_id
    ? product_info.category_id
    : 0;
  let totalProductCost = 0;
  let totalOriginalProductCost = 0;
  if (selected_variation && selected_variation.variation_id) {
    productJson.variation_id = selected_variation.variation_id;
    totalProductCost += selected_variation.variation_cost;
    totalOriginalProductCost += selected_variation.variation_cost;
  }
  if (totalProductCost == 0) {
    totalProductCost += product_info.item_cost;
    totalOriginalProductCost += product_info.item_cost;
  }
  selected_modifiers.forEach((modifier) => {
    totalProductCost += modifier.modifier_cost;
    totalOriginalProductCost += modifier.modifier_cost;
  });
  // Check if coupon (% based only) applied or not
  // if (
  //   state.couponInfo.applied &&
  //   state.couponInfo.type == discountTypeLookup.PERCENT_BASED
  // ) {
  //   let discountPercent = state.couponInfo.value;
  //   let originalTotal = totalProductCost;
  //   // let discount_product_values = state.couponInfo.discount_product_values;
  //   let discount_product_values = JSON.parse(
  //     JSON.stringify(state.couponInfo.discount_product_values)
  //   );
  //   totalProductCost = (+(
  //     totalProductCost -
  //     totalProductCost * (discountPercent / 100)
  //   )).toFixed(2);

  //   discount_product_values.push({
  //     oldValue: originalTotal * quantity,
  //     newValue: totalProductCost * quantity,
  //   });
  //   couponChange = true;
  //   newCouponInfo = JSON.parse(JSON.stringify(state.couponInfo));
  //   newCouponInfo.discount_product_values = discount_product_values;
  // }

  productJson.taxes = [];
  let totalProductTaxes = 0;
  product_info.taxes.forEach((tax) => {
    console.log('tax 1', tax)
    let taxObject = tax_list.find(
      (taxValues) => taxValues.tax_id == tax.tax_id
    );
    // let taxObject = tax_list.filter((taxValues) => taxValues.tax_id == tax.tax_id);
    console.log("taxObject ", taxObject)
    console.log("totalProductCost ", totalProductCost)
    // Categorize tax types
    // let taxType1 = taxObject.filter(taxObj => taxObj.tax_type === 1); // Fixed tax
    // let taxType2 = taxObject.filter(taxObj => taxObj.tax_type === 2); // Percentage-based tax
    // let totalFixedTax = 0;
    // let totalPercentageTax = 0;

    // If there are fixed tax values (tax_type === 1), sum them up
    // if (taxType1.length > 0) {
    //   totalFixedTax = taxType1.reduce((total, taxObj) => total + +taxObj.tax_value, 0);
    // }

    // // If there are percentage-based taxes (tax_type === 2), calculate total percentage tax
    // if (taxType2.length > 0) {
    //   totalPercentageTax = taxType2.reduce((total, taxObj) => total + (totalProductCost * (taxObj.tax_value / 100)), 0);
    // }

    // Calculate the final total tax
    // let totalTaxAmount = totalFixedTax + totalPercentageTax;
    // console.log("totalFixedTax", totalFixedTax);
    // console.log("totalPercentageTax", totalPercentageTax);
    // console.log("totalTaxAmount", totalTaxAmount);
    let taxAmount = taxObject.tax_type === 1
      ? +taxObject.tax_value
      : totalProductCost * (taxObject.tax_value / 100);

    // let taxAmount = totalProductCost * (taxObject.tax_value / 100);
    console.log('totalTaxAmount 1', taxAmount)
    totalProductTaxes = +totalProductTaxes + +taxAmount;
    console.log('totalProductTaxes 1', totalProductTaxes)
    productJson.taxes.push({
      tax_id: taxObject.tax_id,
      tax_amount: (taxAmount * quantity).toFixed(2),
    });
  });
  console.log("product_info.taxes ", product_info.taxes)
  productJson.taxInfo = product_info.taxes;
  console.log("productJson.taxInfo ", productJson.taxInfo)
  productJson.product_total = +(totalProductCost * quantity).toFixed(2);
  productJson.original_total = +(totalOriginalProductCost * quantity).toFixed(
    2
  );
  productJson.product_tax = (+(totalProductTaxes * quantity)).toFixed(2);
  let modifiersArray = [];
  selected_modifiers.forEach((modifier) => {
    modifiersArray.push({ modifier_id: modifier.modifier_id });
  });
  productJson.modifiers = modifiersArray;

  return {
    selected_products: [...state.selected_products, productJson],
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: {
      applied: false,
      discount_product_values: [],
      type: "",
      name: "",
      value: "",
      error: false,
    },
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const removeProduct = (state, { product_index }) => {
  let selectedProducts = JSON.parse(JSON.stringify(state.selected_products));
  selectedProducts.splice(product_index, 1);
  return {
    selected_products: selectedProducts,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const clearOrder = (state) => {
  return {
    selected_products: [],
    restuarantInfo: { business_id: "", location_id: "", favourite: "" },
    playerId: state.playerId,
    finalOrderJson: {},
    orderStatus: "",
    tipIndex: 1,
    tipInputValue: 0,
    couponInfo: { applied: false, discount_product_values: [] },
    phoneDeliveryFee: null,
  };
};

export const placeOrder = (
  state,
  {
    payment_type,
    business_id,
    location_id,
    order_type,
    user_info,
    selected_address,
    payment_info,
    is_schedule_order,
    order_time,
    bill_total,
    delivery_fee,
    instructions,
    coupon_amount,
    currentUrl,
  }
) => {
  let selected_products = JSON.parse(JSON.stringify(state.selected_products));
  selected_products.forEach((prod) => delete prod.taxInfo);

  let finalOrderJson = {
    customer_name: user_info.customer_name,
    customer_phone: user_info.customer_phone,
    customer_id: user_info.customer_id,
    business_id,
    location_id,
    ext_del_id: state.restuarantInfo.info.door_dash_delivery_id,
    products: selected_products,
    order_type: order_type,
    sub_total: OrderSelectors.getSubTotal({ order: state }),
    original_subtotal: OrderSelectors.getOldSubTotal({ order: state }),
    tax: OrderSelectors.getTotalTaxes({ order: state }),
    bill_total: bill_total,
    tax_list: OrderSelectors.getTotalTaxList({ order: state }),
    token: user_info.token,
    address: selected_address?.address,
    payment_type: payment_type,
    is_schedule_order: is_schedule_order ? 1 : 0,
    order_time: order_time,
    tip_cost: OrderSelectors.getTipValue({ order: state }),
    delivery_fee: delivery_fee,
    coupon_id: state.couponInfo.applied ? state.couponInfo.name : "",
    coupon_amount: coupon_amount,
    instructions: instructions,
  };

  if (payment_type == 2) {
    if (payment_info.ssl_token) {
      finalOrderJson.card_number = payment_info.card_number;
      finalOrderJson.ssl_token = payment_info.ssl_token;
      finalOrderJson.card_name = payment_info.card_name;
    } else {
      finalOrderJson.card_number = encrypt(payment_info.card_number);
      finalOrderJson.expire_month = payment_info.expire_month;
      finalOrderJson.expire_year = payment_info.expire_year;
      finalOrderJson.card_postal_code = payment_info.card_postal_code;
      finalOrderJson.cvv = encrypt(payment_info.cvv);
    }
  }

  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const placeBookingOrder = (
  state,
  {
    payment_type,
    business_id,
    location_id,
    user_info,
    payment_info,
    order_time,
    bill_total,
    order_type,
    selected_address,
    is_schedule_order,
    delivery_fee,
    instructions,
    coupon_amount,
    currentUrl,
  }
) => {
  let selected_products = JSON.parse(JSON.stringify(state.selected_products));
  selected_products.forEach((prod) => delete prod.taxInfo);

  let finalOrderJson = {
    customer_name: payment_info.customer_name,
    customer_phone: payment_info.customer_phone,
    customer_id: user_info.customer_id,
    business_id,
    location_id,
    ext_del_id: null,
    products: selected_products,
    order_type: 2,
    sub_total: OrderSelectors.getSubTotal({ order: state }),
    original_subtotal: OrderSelectors.getOldSubTotal({ order: state }),
    tax: OrderSelectors.getTotalTaxes({ order: state }),
    bill_total: bill_total,
    tax_list: OrderSelectors.getTotalTaxList({ order: state }),
    token: user_info.token,
    address: null,
    payment_type: payment_type,
    is_schedule_order: 1,
    order_time: order_time,
    tip_cost: OrderSelectors.getTipValue({ order: state }),
    delivery_fee: "0.00",
    coupon_id: state.couponInfo.applied ? state.couponInfo.name : "",
    coupon_amount: "",
    instructions: instructions,
    appointment: [payment_info],
  };

  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const setNewRestaurant = (
  state,
  {
    business_id,
    location_id,
    intersection_plaza,
    favourite,
    infoObj,
    resetProduct = true,
  }
) => {
  return {
    selected_products: resetProduct ? [] : state.selected_products,
    restuarantInfo: {
      business_id,
      location_id,
      intersection_plaza,
      favourite,
      info: infoObj,
    },
    playerId: state.playerId,
    finalOrderJson: resetProduct ? {} : state.finalOrderJson,
    orderStatus: resetProduct ? "" : state.orderStatus,
    tipIndex: resetProduct ? 1 : state.tipIndex,
    tipInputValue: resetProduct ? 0 : state.tipInputValue,
    couponInfo: resetProduct
      ? {
        applied: false,
        discount_product_values: [],
        type: "",
        name: "",
        value: "",
        error: false,
      }
      : state.couponInfo,
    phoneDeliveryFee: resetProduct ? null : state.phoneDeliveryFee,
  };
};

export const setPhoneDeliveryFee = (
  state,
  { business_id, location_id, latitude, longitude }
) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: 0.0,
  };
};

export const successPhoneDeliveryFee = (state, { delivery_fee }) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: delivery_fee,
  };
};

export const errorPhoneDeliveryFee = (state) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: null,
  };
};

export const setPlayerId = (state, { playerId }) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const sendOrder = (state) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: "Sent",
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};
export const sendAppointmentOrder = (state) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: "Sent",
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const orderSuccessful = (state) => {
  return {
    selected_products: [],
    restuarantInfo: { business_id: "", location_id: "" },
    playerId: state.playerId,
    finalOrderJson: {},
    orderStatus: "",
    tipIndex: 1,
    tipInputValue: 0,
    couponInfo: { applied: false, discount_product_values: [] },
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const phoneOrderSuccessful = (state) => {
  return {
    selected_products: [],
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: {},
    orderStatus: "",
    tipIndex: 4,
    tipInputValue: 0,
    couponInfo: { applied: false, discount_product_values: [] },
    phoneDeliveryFee: null,
  };
};

export const orderFailed = (state) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: "Fail",
    tipIndex: state.tipIndex,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const setTipIndex = (state, { tipIndexValue }) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: tipIndexValue,
    tipInputValue: state.tipInputValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const setTipInputValue = (state, { tipValue }) => {
  return {
    selected_products: state.selected_products,
    restuarantInfo: state.restuarantInfo,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: 3,
    tipInputValue: tipValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const applyCoupon = (
  state,
  { discountType, value, tax_list, coupon_name }
) => {
  let discountPercent = 0;
  let subTotalCost = 0;
  state.selected_products.forEach(
    (product) => (subTotalCost += +product.product_total)
  );
  subTotalCost = (+subTotalCost).toFixed(2);
  if (+discountType == discountTypeLookup.VALUE_BASED && value > subTotalCost) {
    return {
      selected_products: state.selected_products,
      restuarantInfo: state.restuarantInfo,
      playerId: state.playerId,
      finalOrderJson: state.finalOrderJson,
      orderStatus: state.orderStatus,
      tipIndex: state.tipIndex,
      tipInputValue: state.tipInputValue,
      couponInfo: {
        applied: false,
        discount_product_values: "",
        type: "",
        name: "",
        value: "",
        error: true,
      },
    };
  } else {
    if (+discountType == discountTypeLookup.VALUE_BASED) {
      discountPercent = (value / subTotalCost) * 100;
    } else {
      discountPercent = value;
    }
    let discount_product_values = [];
    let selectedProducts = JSON.parse(JSON.stringify(state.selected_products));
    selectedProducts.forEach((prod) => {
      const originalTotal = prod.product_total;

      prod.product_total = (+(
        prod.product_total -
        prod.product_total * (discountPercent / 100)
      )).toFixed(2);
      prod.original_total = originalTotal;
      discount_product_values.push({
        oldValue: originalTotal,
        newValue: prod.product_total,
      });
      let totalProductTaxes = 0;
      let taxesArray = [];
      prod.taxInfo.forEach((tax) => {
        let taxObject = tax_list.find(
          (taxValues) => taxValues.tax_id == tax.tax_id
        );
        // let taxAmount = prod.product_total * (taxObject.tax_value / 100);
        let taxAmount = taxObject.tax_type === 1
          ? +taxObject.tax_value
          : prod.product_total * (taxObject.tax_value / 100);

        totalProductTaxes += taxAmount;
        taxesArray.push({
          tax_id: taxObject.tax_id,
          tax_amount: taxAmount.toFixed(2),
        });
      });
      prod.taxes = taxesArray;
      prod.product_tax = totalProductTaxes;
    });
    let tipValue = 0;
    if (state.tipIndex !== 3 && state.tipIndex !== 4) {
      var newSubTotalCost = 0;
      selectedProducts.forEach(
        (product) => (newSubTotalCost += +product.product_total)
      );
      newSubTotalCost = (+newSubTotalCost).toFixed(2);
      tipValue = calculateTip(state.tipIndex, newSubTotalCost);
    }

    return {
      selected_products: selectedProducts,
      restuarantInfo: state.restuarantInfo,
      playerId: state.playerId,
      finalOrderJson: state.finalOrderJson,
      orderStatus: state.orderStatus,
      tipIndex: state.tipIndex,
      tipInputValue:
        state.tipIndex == 3 || state.tipIndex == 4
          ? state.tipInputValue
          : tipValue,
      couponInfo: {
        applied: true,
        discount_product_values: discount_product_values,
        type: discountType,
        name: coupon_name,
        value: value,
        error: false,
      },
      phoneDeliveryFee: state.phoneDeliveryFee,
    };
  }
};

export const setNoOfGuests = (state, { noOfGuest }) => {
  let info = state.restuarantInfo;
  info.noOfGuest = noOfGuest;
  return {
    selected_products: state.selected_products,
    restuarantInfo: info,
    playerId: state.playerId,
    finalOrderJson: state.finalOrderJson,
    orderStatus: state.orderStatus,
    tipIndex: state.tipIndex,
    tipInputValue: state.tipValue,
    couponInfo: state.couponInfo,
    phoneDeliveryFee: state.phoneDeliveryFee,
  };
};

export const removeCoupon = (state, { tax_list }) => {
  if (state.couponInfo.applied) {
    let selectedProducts = JSON.parse(JSON.stringify(state.selected_products));
    selectedProducts.forEach((prod, index) => {
      prod.product_total =
        state.couponInfo.discount_product_values[index]?.oldValue;
      let totalProductTaxes = 0;
      let taxesArray = [];
      prod.taxInfo.forEach((tax) => {
        let taxObject = tax_list.find(
          (taxValues) => taxValues.tax_id == tax.tax_id
        );
        // let taxAmount = prod.product_total * (taxObject.tax_value / 100);
        let taxAmount = taxObject.tax_type === 1
          ? +taxObject.tax_value
          : prod.product_total * (taxObject.tax_value / 100);

        totalProductTaxes += taxAmount;
        taxesArray.push({
          tax_id: taxObject.tax_id,
          tax_amount: taxAmount.toFixed(2),
        });
      });
      prod.taxes = taxesArray;
      prod.product_tax = totalProductTaxes;
    });
    let tipValue = 0;
    if (state.tipIndex !== 3 && state.tipIndex !== 4) {
      let newSubTotalCost = 0;
      selectedProducts.forEach(
        (product) => (newSubTotalCost += +product.product_total)
      );
      newSubTotalCost = (+newSubTotalCost).toFixed(2);
      tipValue = calculateTip(state.tipIndex, newSubTotalCost);
    }

    return {
      selected_products: selectedProducts,
      restuarantInfo: state.restuarantInfo,
      playerId: state.playerId,
      finalOrderJson: state.finalOrderJson,
      orderStatus: state.orderStatus,
      tipIndex: state.tipIndex,
      tipInputValue: state.tipIndex == 3 ? state.tipInputValue : tipValue,
      couponInfo: {
        applied: false,
        discount_product_values: [],
        type: "",
        name: "",
        value: "",
      },
      phoneDeliveryFee: state.phoneDeliveryFee,
    };
  }
};

// New reducer to handle the action
export const updateSelectedOrder = (state, { selectedOrder }) => {
  return {
    ...state,
    selectedOrder,
  };
};

export const reducer = createReducer(INITIAL_ORDER_STATE, {
  [Types.ADD_PRODUCT]: addProduct,
  [Types.REMOVE_PRODUCT]: removeProduct,
  [Types.CLEAR_ORDER]: clearOrder,
  [Types.PLACE_ORDER]: placeOrder,
  [Types.PLACE_BOOKING_ORDER]: placeBookingOrder,
  [Types.SET_NEW_RESTAURANT]: setNewRestaurant,
  [Types.SET_PLAYER_ID]: setPlayerId,
  // [Types.SET_FAVOURTIE]: setFavouriteStatus,
  [Types.SEND_ORDER]: sendOrder,
  [Types.SEND_APPOINTMENT_ORDER]: sendAppointmentOrder,
  [Types.ORDER_SUCCESSFUL]: orderSuccessful,
  [Types.PHONE_ORDER_SUCCESSFUL]: phoneOrderSuccessful,
  [Types.ORDER_FAIL]: orderFailed,
  [Types.SET_TIP_INDEX]: setTipIndex,
  [Types.SET_TIP_INPUT_VALUE]: setTipInputValue,
  [Types.APPLY_COUPON]: applyCoupon,
  [Types.REMOVE_COUPON]: removeCoupon,
  [Types.SET_PHONE_DELIVERY_FEE]: setPhoneDeliveryFee,
  [Types.SUCCESS_PHONE_DELIVERY_FEE]: successPhoneDeliveryFee,
  [Types.ERROR_PHONE_DELIVERY_FEE]: errorPhoneDeliveryFee,
  [Types.UPDATE_SELECTED_ORDER]: updateSelectedOrder,
});
